import Cookies from 'js-cookie'

const TokenKey = 'atu_video_resolution_popup'

export function isVideoResolutionPopupDisabled() {
    return true;
}

export function disableVideoResolutionPopup() {
    return Cookies.set(TokenKey, true)
}
