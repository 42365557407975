import $ from "jquery";
import { Timer } from "easytimer.js";

export default function () {
    let stopwatch = new Timer();

    $(".maincontent").on("click", ".startButton", function (e) {
        e.preventDefault();
        $(".startButton").hide();
        $("#startStopwatch").hide();
        $("#pauseStopwatch").show();
        $(".finishButton").show().css("display", "inline-flex");
        $(".exercise_control").fadeIn(200);
        stopwatch.start();
    });

    $(".maincontent").on("click", ".finishButton", function (e) {
        e.preventDefault();
        $(".finishButton").hide();
        $(".startButton").show().css("display", "inline-flex");
        $(".exercise_control").fadeOut(200);
        stopwatch.stop();
    });

    $('#startStopwatch').click(function (e) {
        e.preventDefault();
        $('#startStopwatch').hide();
        $(".startButton").hide();
        $("#pauseStopwatch").show();
        $(".finishButton").show().css("display", "inline-flex");
        $(".exercise_control").fadeIn(200);
        stopwatch.start();
    });

    $('#pauseStopwatch').click(function () {
        $(this).hide();
        $("#startStopwatch").show();
        stopwatch.pause();
    });

    $('#stopStopwatch').click(function () {
        $(".finishButton").hide();
        $(".startButton").show().css("display", "inline-flex");
        $(".exercise_control").fadeOut(200);
        stopwatch.stop();
    });

    $("body").on("click", ".exercise_control a", function (e) {
        console.log("there is an anchor tag inside the Control.vue section");
        e.preventDefault();
        $(".finishButton").hide();
        $(".startButton").show().css("display", "inline-flex");
        $(".exercise_control").fadeOut(200);
        stopwatch.stop();
    });

    stopwatch.addEventListener('secondsUpdated', function () {
        $('.stopwatchTime').html(stopwatch.getTimeValues().toString());
    });

    stopwatch.addEventListener('started', function () {
        $('.stopwatchTime').html(stopwatch.getTimeValues().toString());
    });

    stopwatch.addEventListener('stopped', function () {
        $('.stopwatchTime').html(stopwatch.getTimeValues().toString());
    });

    $("#swapGym").on("click", ".exswap_close", function (e) {
        e.preventDefault();
        $(".exercise_swap").fadeOut(200);
        $("body").removeClass("modalopen")
    });

    $(".description_popup").on("click", ".exswap_close", function (e) {
        e.preventDefault();
        $(".description_popup").fadeOut(200);
        $("body").removeClass("modalopen")
    });

    $(".exercise_swap").on("click", ".exswap_cancel", function (e) {
        e.preventDefault();
        $(".exercise_swap").fadeOut(200);
        $("body").removeClass("modalopen")
    });

    $(".maincontent").on("keyup", function (e) {
        if (e.key === "Escape") {
            $(".exercise_swap").fadeOut(200);
            $("body").removeClass("modalopen")
        }
    });

    $(".maincontent").on("mouseup", function (e) {
        if (e.target.closest('.exswap') === null) {
            $(".exercise_swap").fadeOut(200);
            $("body").removeClass("modalopen")
        }
    });

    $(".maincontent").on("click", ".band_select", function (e) {
        e.preventDefault();
        $(this).closest(".exercise_input_td").find(".band_select_dropdown").slideDown(200);
    });

    $(".maincontent").on("click", ".band_select_item", function (e) {
        e.preventDefault();
        let currentBandColor = $(this).find(".exercise_band").attr('style');
        $(this).closest(".exercise_input_td").find(".band_select").attr('style', currentBandColor)
        $(".band_select_dropdown").slideUp(200);
    });

    $(".maincontent").on("mouseup", function (e) {
        if (e.target.closest('.band_select_dropdown') === null) {
            $(".band_select_dropdown").fadeOut(200);
        }
    });

    $(document).on("click", ".workouts_ex_close", function (e) {
        e.preventDefault();
        currentVideoForPlay.pause();
        $(currentVideoForPlay).attr("controls", false)
        $(this).closest(".workouts_exitem").removeClass("moreshowed").removeClass("videoplaying");
        $(this).closest(".workouts_exitem").find(".workouts_ex_more").fadeOut();
    });

    $(".maincontent").on("click", ".workouts_ex_img .playvideo", function (e) {
        e.preventDefault();
        $(this).closest(".workouts_exitem").addClass("videoplaying");
    });

    $(".maincontent").on("change", ".workouts_ex_done input[type='checkbox']", function () {
        let allDone = $(".workouts_ex_done input[type='checkbox']").length;
        if ($(this).is(':checked')) {
            if ($(".workouts_ex_done input[type='checkbox']:checked").length === allDone) {
                $(".exercise_congratulations").fadeIn(200);
            } else {
                let toScroll = $(this).closest(".workouts_exitem").next(".workouts_exitem");
                let toScrollHeight = $(".header").outerHeight() + 10;
                $('html, body').animate({
                    scrollTop: $(toScroll).offset().top - toScrollHeight
                }, 1000);
            }
        }
    });

    $(document).on("click", ".showDescription", function (e) {
        e.preventDefault();
        $(".description_popup").fadeIn(200);

    });

    $(".maincontent").on("click", ".exercise_notes_opener", function (e) {
        e.preventDefault();
        $(this).hide();
        $(this).closest(".exercise_notes").find("textarea").show().focus();
    })

    let playingItem = undefined;
    let currentVideoForPlay;

    $(document).on("click", ".workouts_exitem .playvideo", function () {
        playingItem = $(this).closest(".workouts_exitem");
        currentVideoForPlay = $(this).closest(".workouts_exitem").find("video")[0];
        currentVideoForPlay.load();
        $(currentVideoForPlay).attr("controls", true)
        currentVideoForPlay.play();
        $(this).closest(".workouts_exitem").addClass("videoplaying")
    });

    $(".maincontent").on("mouseup", function (e) {
        if (!$(playingItem).is(e.target) && $(playingItem).has(e.target).length === 0 && $(playingItem).find('input:focus').length === 0 && playingItem !== undefined) {
            currentVideoForPlay.pause();
            $(currentVideoForPlay).attr("controls", false)
        }
    });

    $(".maincontent").on("click", ".workouts_ex_timer", function (e) {
        e.preventDefault();
        $("#startStopwatch").show();
        $("#pauseStopwatch").hide();
        $(".exercise_control").fadeIn(200);
        stopwatch.stop();
    });

}