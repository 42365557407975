var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workouts_exitem"},[_c('div',{staticClass:"workouts_ex_img"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showQualitySettingsIcon),expression:"showQualitySettingsIcon"}],staticClass:"workouts_ex_quality",on:{"click":function($event){_vm.showVideoResolutionButtons = true}}},[_c('i',{staticClass:"atuicons atuicons-video-setting"})]),_vm._m(0),_c('div',{staticClass:"workouts_ex__img",style:({
        backgroundImage: ("url(" + (_vm.exercise.files.thumbnail) + ")"),
      })},[_c('video',{attrs:{"id":("swap_video_" + (_vm.exercise.id)),"poster":_vm.exercise.files.thumbnail,"controlsList":"nodownload"}},[_c('source',{attrs:{"id":("swap_source_" + (_vm.exercise.id)),"src":_vm.videoSrc,"type":"video/mp4"}})])]),_vm._m(1),_c('div',{staticClass:"workouts_ex_showdesc"},[_c('a',{staticClass:"title_small showDescription",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showDetail(_vm.exercise, _vm.serialNumber + 1)}}},[_vm._v("Read Instructions")])]),_c('div',{class:{
        workouts_ex_buttons: true,
        opened: _vm.showVideoResolutionButtons,
      }},[_c('button',{class:{
          button: true,
          button_white: true,
          current: 'low' == _vm.selectedVideoResolution,
        },attrs:{"data-quality":"low"},on:{"click":function($event){return _vm.changeVideoResolution('low')}}},[_c('i',{staticClass:"atuicons atuicons-tick"}),_vm._v("Lower resolution")]),_c('button',{class:{
          button: true,
          button_white: true,
          current: 'high' == _vm.selectedVideoResolution,
        },attrs:{"data-quality":"high"},on:{"click":function($event){return _vm.changeVideoResolution('high')}}},[_c('i',{staticClass:"atuicons atuicons-tick"}),_vm._v("High resolution ")])])]),_c('div',{staticClass:"workouts_ex_description"},[_c('div',{staticClass:"workouts_ex_muscles",style:({
        backgroundImage: ("url(" + (_vm.exercise.image) + ")"),
      })}),_c('div',{staticClass:"workouts_ex_title"},[_vm._v(" #"+_vm._s(_vm.serialNumber + 1)+". "+_vm._s(_vm.exercise.name)+" ")]),_c('div',{staticClass:"workouts_ex_setreps"},[(_vm.exercise.sets)?_c('div',{staticClass:"workouts_ex_sets"},[_c('div',{staticClass:"workouts_ex_setreps_t"},[_vm._v(" SETS: "),_c('strong',[_vm._v(_vm._s(_vm.exercise.sets))])])]):_vm._e(),(_vm.exercise.reps)?_c('div',{staticClass:"workouts_ex_reps"},[_c('div',{staticClass:"workouts_ex_setreps_t"},[_vm._v(" REPS: "),_c('strong',[_vm._v(_vm._s(_vm.exercise.reps))])])]):_vm._e(),(_vm.exercise.sideType)?_c('div',{staticClass:"workouts_ex_reps"},[_c('div',{staticClass:"workouts_ex_setreps_t"},[_vm._v(" SIDE: "),_c('strong',[_vm._v(_vm._s(_vm.exercise.sideType))])])]):_vm._e(),(_vm.exercise.time)?_c('div',{staticClass:"workouts_ex_reps"},[_c('div',{staticClass:"workouts_ex_setreps_t"},[_vm._v(" TIME: "),_c('strong',[_vm._v(_vm._s(_vm.exercise.time))])])]):_vm._e()])]),_c('div',{staticClass:"exswap_selectbutton"},[_c('a',{staticClass:"button button_small button_grey",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleSelect(_vm.exercise, false)}}},[_c('span',[_vm._v("Select")]),_c('i',{staticClass:"atuicons atuicons-tick"})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workouts_ex_close"},[_c('i',{staticClass:"atuicons atuicons-close"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playvideo"},[_c('a',{staticClass:"playbutton",attrs:{"href":"#"}}),_c('div',{staticClass:"title_small"},[_vm._v("Play Video")])])}]

export { render, staticRenderFns }