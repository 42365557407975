<template>
  <Fragment>
    <main class="maincontent">
      <div
        class="show_instructions"
        @click="playIntro"
        v-if="!profile.hasStoppedNewDesignIntro"
      >
        Show instructions for new design
      </div>
      <div class="mainh1 mainh1_workouts">
        <div class="wrapper">
          <div class="mainh1_flex mainh1_center">
            <h1>Today's Workout</h1>
          </div>
        </div>
      </div>

      <section class="workouts" v-if="profile.isPremiumMember">
        <div class="wrapper" v-if="workout">
          <div class="workouts_head">
            <div class="workouts_today">
              <div class="workouts_today_content workouts_today_headnav">
                <div v-show="(day+month > 2) && !isLoading" class="workouts_prevday">
                  <a @click.prevent="prev" href="#"
                    ><i class="atuicons atuicons-arrowleft"></i
                    ><span>Previous Day</span></a
                  >
                </div>
                <div class="workouts_last2">
                  <div class="workouts_last2_text" v-show="!hideTitle">
                    Today is:
                  </div>
                  <div class="workouts_last2_item">
                    Month {{ this.month }} / Day
                    {{ this.day }}
                  </div>
                  <div class="workouts_last2_text" v-show="!hideTitle">
                    based on your completed workout log
                  </div>
                </div>

                <div v-show="!isLoading" class="workouts_nextday">
                  <a @click.prevent="next" href="#"
                    ><span>Next Day</span
                    ><i class="atuicons atuicons-arrowright"></i
                  ></a>
                </div>
              </div>
              <div class="workouts_today2">
                <div class="workouts_today2_calendar">
                  <router-link to="/schedule/index"
                    >see full calendar</router-link
                  >
                </div>
              </div>
            </div>
            <div v-if="!isRestDay()" class="workouts_gymhome">
              <a
                @click.prevent="setType('gym')"
                :class="{ button_green: type == 'gym', button_red: true }"
                ><i class="atuicons atuicons-exgym"></i>For Gym</a
              >
              <a
                @click.prevent="setType('home')"
                :class="{ button_green: type == 'home', button_red: true }"
                ><i class="atuicons atuicons-exhome"></i>For Home</a
              >
            </div>
          </div>

          <div class="wrapper" v-if="!isLoading && !workout">
            <h2 style="text-align: center">No Workout Found!</h2>
          </div>

          <div class="workouts_exercises">
            <Challenge v-if="!isRestDay()"></Challenge>
            <ExerciseList
              v-if="!isLoading"
              :workout="workout"
              :info="info"
            ></ExerciseList>
            <Congratulation
              v-if="!isRestDay() && !isLoading"
              :info="info"
            ></Congratulation>
            <RestDay v-if="isRestDay() && !isLoading"></RestDay>
          </div>
        </div>
      </section>
      <section v-if="!profile.isPremiumMember">
        <div class="wrapper">
          <BuyAlert></BuyAlert>
        </div>
      </section>
    </main>
    <DescriptionPopup></DescriptionPopup>
    <Control></Control>
    <Swap :information="info"></Swap>
    <div class="intro_popup">
      <div class="intro_popup_center">
        <div class="intro_close" @click="closeIntro">
          <i class="atuicons atuicons-close"></i>
        </div>
        <div class="intro_popup_content">
          <div class="intro_popup_video">
            <video
              :poster="profile.introVideo.image_url"
              controlsList="nodownload"
              controls=""
            >
              <source :src="profile.introVideo.video_url" type="video/mp4" />
            </video>
          </div>
          <div class="intro_popup_dontshow">
            <a @click.prevent="dontShowIntro" class="button button_red" href="#"
              >Close and Don't show this video again</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="buysubscription" :style="this.videoResolutionStyle">
      <div class="buys_center">
        <div class="buys">
          <div class="buys_head">
            <div class="buys_close" @click="hideVideoResolutionPopup">
              <i class="atuicons atuicons-close"></i>
            </div>
          </div>
          <div class="termsandconditions_d">
            <div class="intro_popup_text">
              <div class="title_small">
                Are you using lower speed internet on your mobile device?
              </div>
              <p>Choose the "low resolution" video playback option</p>
              <img :src="require(`@/assets/img/v-q-2.jpg`)" />
              <p>
                Use Video Resolution in Settings to choose your default video
                quality
              </p>
              <img :src="require(`@/assets/img/v-q-1.jpg`)" />
            </div>
            <div class="termsandconditions_b">
              <button
                class="button button_red"
                @click="disableVideoResolutionPopup"
              >
                Close and Don't show this again
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import {
  isVideoResolutionPopupDisabled,
  disableVideoResolutionPopup,
} from "@/utils/video-resolution-popup";
import NProgress from "nprogress";
NProgress.configure({ showSpinner: false });
import swal from "sweetalert";
import "nprogress/nprogress.css";
import $ from "jquery";

import custom_js from "./custom";
import { Fragment } from "vue-fragment";
import { getTodayWorkout } from "@/api/workout";
import {
  ExerciseList,
  Challenge,
  Control,
  DescriptionPopup,
  Swap,
  Congratulation,
  RestDay,
} from "./components";

import BuyAlert from "@/components/BuyAlert";

export default {
  name: "Workout",
  components: {
    Fragment,
    Challenge,
    ExerciseList,
    Control,
    DescriptionPopup,
    Swap,
    Congratulation,
    RestDay,
    BuyAlert,
  },
  data() {
    return {
      videoResolutionStyle: {
        display: isVideoResolutionPopupDisabled() ? "none" : "block",
      },
      workout: null,
      info: null,
      isLoading: false,
      type: "home",
      month: 1,
      day: 1,
      last: { day: 0, month: 0 },
      hideTitle: true,
    };
  },
  created() {
    if (!this.profile.isPremiumMember) {
      return;
    }

    if (this.$route.query.day && this.$route.query.month) {
      this.day = parseInt(this.$route.query.day);
      this.month = parseInt(this.$route.query.month);
      if (this.day > 0 && this.month > 0) {
        console.log("fetching today workout on created event");
        this.hideTitle = true;
        this.fetchData();
      } else {
        console.log(
          "could not find correct day and month in the route so cannot fetch workout"
        );
      }
      return;
    }
    this.updateLastWorkoutLog();
  },
  methods: {
    hideVideoResolutionPopup() {
      this.videoResolutionStyle.display = "none";
    },
    disableVideoResolutionPopup() {
      disableVideoResolutionPopup();
      this.hideVideoResolutionPopup();
    },
    redirect(day, month) {
      this.day = day;
      this.month = month;
      this.hideTitle = true;
      this.replaceQuery();
      this.fetchData();
    },
    updateLastWorkoutLog() {
      this.$store
        .dispatch("workoutlog/update")
        .then(() => {
          console.log(
            "updated the last workout log data",
            this.lastWorkoutLogData
          );
          this.redirectOnNextWorkoutBasedOnCompleted();
        })
        .catch(() => {
          console.log("could not update the last workout log data");
        })
        .finally(() => {});
    },
    dontShowIntro() {
      this.$store
        .dispatch("user/dontShowIntro")
        .then(() => {
          this.closeIntro();
        })
        .catch(() => {
          console.log("could not execute agreement");
        })
        .finally(() => {});
    },
    closeIntro() {
      $(".intro_popup video")[0].pause();
      $(".intro_popup").fadeOut(200);
    },
    playIntro() {
      setTimeout(function () {
        $(".intro_popup").fadeIn(200);
      }, 1000);
      setTimeout(function () {
        if ($(".intro_popup video").length) {
          $(".intro_popup video")[0].load();
        }
      }, 2000);
    },
    customjs() {
      custom_js();
    },
    setType(type) {
      this.type = type;
      this.hideTitle = true;
      this.fetchData();
    },
    prev() {
      this.day = this.day - 1;
      console.log('preday', this.day);

      if(0 === this.day){
        this.day=28;
        this.month=this.month-1;
      }


      this.replaceQuery();
      this.hideTitle = true;
      this.fetchData();
    },
    next() {
      let day = parseInt(this.day);
      if (this.profile.isTrial && day === this.profile.trialDays) {
        swal(
          "Blocked Workout!",
          `You can only access the first ${this.profile.trialDays} days workout during the trial period.`,
          "error"
        );
        return;
      }
      this.day = day + 1;

      if(29 === this.day){
        this.day=1;
        this.month=this.month+1;
      }

      this.replaceQuery();
      this.hideTitle = true;
      this.fetchData();
    },
    fetchData() {
      this.isLoading = true;
      NProgress.start();
      getTodayWorkout({ type: this.type, month: this.month, day: this.day })
        .then(({ data }) => {
          this.workout = data.workout;
          this.info = data.info;
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
    replaceQuery() {
      this.$router
        .replace({
          query: { day: this.day, month: this.month },
        })
        .catch((error) => {
          console.log("router replace error", error);
        });
    },
    isRestDay() {
      return this.workout && "rest" == this.workout.name.toLowerCase();
    },
    redirectOnAccount() {
      if (
        this.profile.isPremiumMember &&
        !this.profile.hasAcceptedTermsConditions &&
        this.$route.path !== "/account/index"
      ) {
        this.$router.push("/account/index").catch(() => {
          console.log(
            "error while redirecting on account page from layout component"
          );
        });
      }
    },
    redirectOnNextWorkoutBasedOnCompleted() {
      let last = this.lastWorkoutLogData;

      if (last.day) {
        let day = parseInt(last.day) + 1;
        let month = parseInt(last.month);

        if (28 == day) {
          month++;
        }

        this.day = day;
        this.month = month;
      }

      this.replaceQuery();
      this.hideTitle = false;
      this.fetchData();
    },
  },
  mounted: function () {
    this.customjs();
    this.redirectOnAccount();

    $(document).on('click', ".workouts_exlist .playvideo", function () {
      let isLast = $(this).closest(".workouts_exitem").next().length;
      if (isLast === 0) {
        $(".exercise_markdone").addClass("workoutStarted");
      }
    });

    if ($(".show_instructions").length) {
      $(".show_instructions").trigger("click");
    }
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    lastWorkoutLogData() {
      return this.$store.getters.lastWorkoutLog;
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/workouts.css";
</style>