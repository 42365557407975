<template>
  <div
    :class="{ workouts_exitem: true, moreshowed: isExerciseLogsLoaded }"
    :data-item_id="`${serialNumber}-${exercise.id}`"
  >
    <div class="workouts_ex_img">
      <div
        class="workouts_ex_quality"
        v-show="showQualitySettingsIcon"
        @click="showVideoResolutionButtons = true"
      >
        <i class="atuicons atuicons-video-setting"></i>
      </div>
      <div class="workouts_ex_close">
        <i class="atuicons atuicons-close"></i>
      </div>

      <div class="workouts_ex__img">
        <video
          :poster="exercise.files.thumbnail"
          controlsList="nodownload"
          :id="`video_${uniqueKey}`"
        >
          <source
            :id="`source_${uniqueKey}`"
            :src="videoSrc"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="playvideo">
        <a class="playbutton" href="javascript:void(0)"></a>
        <div class="title_small">Play Video</div>
      </div>
      <div class="workouts_ex_showdesc">
        <a
          class="title_small showDescription"
          @click.prevent="showDetail(exercise, serialNumber)"
          href="#"
          >Show Description</a
        >
      </div>
      <div
        :class="{
          workouts_ex_buttons: true,
          opened: showVideoResolutionButtons,
        }"
      >
        <button
          :class="{
            button: true,
            button_white: true,
            current: selectedVideoResolution == 'low',
          }"
          data-quality="low"
          @click="changeVideoResolution('low')"
        >
          <i class="atuicons atuicons-tick"></i>Lower resolution</button
        ><button
          :class="{
            button: true,
            button_white: true,
            current: selectedVideoResolution == 'high',
          }"
          data-quality="high"
          @click="changeVideoResolution('high')"
        >
          <i class="atuicons atuicons-tick"></i>High resolution
        </button>
      </div>
    </div>
    <div class="workouts_ex_description">
      <div
        class="workouts_ex_muscles"
        :style="{ backgroundImage: `url(${exercise.image})` }"
      ></div>

      <div class="workouts_ex_t">
        <div class="workouts_ex_title">
          #{{ serialNumber }} - {{ exercise.name }}
        </div>
        <div class="workouts_ex_timer">
          <i class="atuicons atuicons-exstart"></i>
        </div>
      </div>

      <div class="workouts_ex_setreps">
        <div class="workouts_ex__setreps">
          <div class="workouts_ex_sets">
            <div class="workouts_ex_setreps_t">
              SETS: <strong>{{ exercise.sets }}</strong>
            </div>
          </div>
          <div class="workouts_ex_reps">
            <div class="workouts_ex_setreps_t">
              REPS: <strong>{{ exercise.reps }}</strong>
            </div>
          </div>
        </div>
        <div class="workouts_ex_readmore" @click="handleLog">
          <span>Record Your Sets</span>
        </div>
      </div>
    </div>
    <div class="workouts_ex_desktopdesc">
      <p>
        {{ exercise.description }}
        <a
          class="showDescription"
          @click.prevent="showDetail(exercise, serialNumber)"
          href="#"
          >Show Full Description</a
        >
      </p>
    </div>
    <div class="exercise_swapbuttons visible">
      <a
        @click.prevent="handleSwap(exercise, workoutId, 'home', serialNumber)"
        class="button button_black swapForHome"
        href="#"
        ><i class="atuicons atuicons-exhome"></i>Swap For a Home Alternative</a
      ><a
        @click.prevent="handleSwap(exercise, workoutId, 'gym', serialNumber)"
        class="button button_black swapForGym"
        href="#"
        ><i class="atuicons atuicons-exgym"></i>Swap For a Gym Alternative</a
      >
    </div>

    <div class="workouts_ex_more">
      <div class="exercise_swapbuttons startbuttons">
        <a
          class="button button_grey showDescription"
          href="#"
          @click.prevent="showDetail(exercise, serialNumber)"
          >Show Description</a
        ><a class="button button_red startButton" href="#"
          ><i class="atuicons atuicons-exstart"></i>Start Timer</a
        ><a
          class="button button_red finishButton"
          href="#"
          style="display: none"
          ><i class="atuicons atuicons-exfinish"></i>Stop Timer</a
        >
      </div>
    </div>
    <ExerciseLog
      v-if="isExerciseLogsLoaded"
      :userExerciseLog="userExerciseLog"
      :exercise="exercise"
    ></ExerciseLog>
  </div>
</template>
<script>
import $ from "jquery";

import NProgress from "nprogress";

import { getLogByExerciseId } from "@/api/exercise-log";
import ExerciseLog from "./components/ExerciseLog";

export default {
  name: "Exercise",
  props: {
    uniqueKey: String,
    exercise: Object,
    serialNumber: Number,
    info: Object,
    completedExerciseList: Array,
    workoutId: Number,
  },
  components: { ExerciseLog },
  data() {
    return {
      showQualitySettingsIcon: true,
      showVideoResolutionButtons: false,
      selectedVideoResolution: "high",
      videoSrc: "",
      isExerciseLogsLoaded: false,
      isSwapLoading: false,
      userExerciseLog: {},
    };
  },
  mounted() {
    this.selectedVideoResolution = this.profile.properties.video_resolution;
    this.videoSrc = this.exercise.files.videos[this.selectedVideoResolution];
  },

  methods: {
    changeVideoResolution(resolution) {
      this.selectedVideoResolution = resolution;
      this.videoSrc = this.exercise.files.videos[this.selectedVideoResolution];
      this.showVideoResolutionButtons = false;

      var video = document.getElementById(`video_${this.uniqueKey}`);
      var source = document.getElementById(`source_${this.uniqueKey}`);
      setTimeout(() => {
        let t = video.currentTime;
        video.pause();
        source.setAttribute("src", this.videoSrc);
        source.setAttribute("type", "video/mp4");
        video.load();
        video.currentTime = t;
        var playPromise = video.play();
        NProgress.start();
        this.showQualitySettingsIcon = false;

        if (playPromise !== undefined) {
          playPromise
            .catch((error) => {
              console.log("exercise video error", error);
            })
            .finally(() => {
              NProgress.done();
              this.showQualitySettingsIcon = true;
            });
        }
        console.log({
          src: source.getAttribute("src"),
          type: source.getAttribute("type"),
        });
      }, 0);
    },
    handleLog(event) {
      this.fetchExerciseLogs();
      let clickedTarget = $(event.target).closest(".workouts_exitem");
      clickedTarget.addClass("moreshowed");
      clickedTarget.find(".workouts_ex_more").fadeIn();
    },
    handleSwap(exercise, workoutId, type, serialNumber) {
      if (this.isSwapLoading) {
        return;
      }

      NProgress.start();
      this.isSwapLoading = true;
      this.$store
        .dispatch("swap/getSwapExercises", {
          exercise,
          workoutId,
          type,
          index: serialNumber - 1,
        })
        .then(() => {
          $("#swapGym").fadeIn(200);
          $("body").addClass("modalopen");
        })
        .catch(() => {
          console.log("error while fetching swappable exercises");
        })
        .finally(() => {
          NProgress.done();
          this.isSwapLoading = false;
        });
    },
    showDetail(exercise, serialNumber) {
      const item = {
        name: exercise.name,
        description: exercise.description,
        serialNumber,
      };
      this.$store.dispatch("exerciseDescription/selectExercise", item);
    },
    fetchExerciseLogs() {
      NProgress.start();
      getLogByExerciseId(this.exercise.id)
        .then((response) => {
          this.isExerciseLogsLoaded = true;
          this.userExerciseLog = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          NProgress.done();
        });
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
};
</script>