<template>
  <div class="exercise_inputs workouts_ex_more" style="display: block">
    <div v-show="userExerciseLog.logs.length > 0" class="exercise_inputs_row exercise_inputs_head">
      <div class="exercise_input_td set">SET</div>
      <div class="exercise_input_td last" v-if="exercise.recordTime">TIME</div>
      <div class="exercise_input_td reps">REPS</div>
      <div class="exercise_input_td lb" v-if="exercise.useWeights">WEIGHT</div>
      <div class="exercise_input_td band" v-if="exercise.useBands">BAND</div>
      <div class="exercise_input_td remove"></div>
    </div>
    <form v-on:submit.prevent="handleSubmit">
      <input type="hidden" name="exerciseId" :value="exercise.id" />
      <div v-for="(log, index) in userExerciseLog.logs" :key="`${exercise.id}-${index}`" class="exercise_inputs_row">
        <div class="exercise_input_td set">
          <div class="exercise_setnumber orange">{{ index + 1 }}</div>
        </div>
        <div class="exercise_input_td last" v-if="exercise.recordTime">
          <input class="exercise_input" type="number" v-model="log.time" />
        </div>
        <div class="exercise_input_td reps">
          <input class="exercise_input" type="number" v-model="log.reps" />
        </div>
        <div class="exercise_input_td lb" v-if="exercise.useWeights">
          <input class="exercise_input" type="number" step="any" min="0" v-model="log.weight" />
        </div>
        <div class="exercise_input_td band" v-if="exercise.useBands">
          <div :class="{
            exercise_band: true,
            band_select: true,
          }" :style="getSelectedBandStyle(index)">
            <i class="atuicons atuicons-arrowdown"></i>
          </div>
          <div class="band_select_dropdown">
            <div v-for="(band, i) in bands" :key="`${exercise.id}-${index}-${i}`" class="band_select_item"
              @click.prevent="userExerciseLog.logs[index].band = band.value">
              <span>{{ band.title }}</span>
              <div class="exercise_band" :style="bandStyles[band.key]"></div>
            </div>
          </div>
        </div>

        <div class="exercise_input_td remove">
          <button class="exercise_remove" @click.prevent="remove(index)" v-show="userExerciseLog.logs.length > 1">
            <i class="atuicons atuicons-close"></i>
          </button>
        </div>
      </div>

      <div class="exercise_notes">
        <div class="exercise_notes_opener">
          + Add or update an optional note
        </div>
        <textarea v-model="userExerciseLog.note"></textarea>
      </div>
      <div class="exercise_inputs_addbutton exercise_inputs_addbutton-two">
        <a class="addSet button button_small button_grey" @click.prevent="add()">+ Add Set</a>
        <a class="DuplicateSet button button_small button_grey" @click.prevent="duplicateLastSet()">+ Duplicate Last
          Set</a>
      </div>
      <div class="exercise_save">
        <SaveButton :isLoading="isLoading" text="Save and Show Next"></SaveButton>
      </div>
    </form>
  </div>
</template>


<script>
import NProgress from "nprogress";
import swal from "sweetalert";
import { saveExerciseLog } from "@/api/exercise-log";
import SaveButton from "@/components/SaveButton";

export default {
  name: "ExerciseLog",
  props: { userExerciseLog: Object, exercise: Object },
  components: { SaveButton },
  data() {
    return {
      isLoading: false,
      bands: [
        { title: "None", value: "", key: "cyan" },
        { title: "Black", value: "black", key: "black" },
        { title: "Red", value: "red", key: "red" },
        { title: "Blue", value: "blue", key: "blue" },
        { title: "Green", value: "green", key: "green" },
        { title: "Yellow", value: "yellow", key: "yellow" },
        { title: "Purple", value: "purple", key: "purple" },
        { title: "Orange", value: "orange", key: "orange" },
      ],
      bandStyles: {
        cyan: {
          background: "#00FFFF",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        black: {
          background: "#2B2B2B",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        red: {
          background: "#FF0000",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        blue: {
          background: "#5359E6",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        green: {
          background: "#3cd26f",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        yellow: {
          background: "#FFFF00",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        purple: {
          background: "#d23ccc",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
        orange: {
          background: "#FFA500",
          border: "2px solid rgba(255, 255, 255, 0.3)",
        },
      },
    };
  },
  methods: {
    getSelectedBandStyle(index) {
      if (
        this.userExerciseLog.logs[index] &&
        this.userExerciseLog.logs[index].band
      ) {
        return this.bandStyles[this.userExerciseLog.logs[index].band];
      }
      return this.bandStyles.cyan;
    },
    handleSubmit(event) {
      this.isLoading = true;
      let formData = {
        exerciseId: this.exercise.id,
        logs: this.userExerciseLog.logs,
        note: this.userExerciseLog.note,
      };
      NProgress.start();
      saveExerciseLog(formData)
        .then((response) => {
          swal(
            "Success",
            response.message || "Your record has been saved successfully!",
            "success"
          );

          let next = event.target.closest('div.workouts_exitem').nextElementSibling;
          if (next) {
            event.target.closest('div.workouts_exitem').nextElementSibling.scrollIntoView({
              behavior: 'smooth'
            });
          }

        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
    remove(index) {
      this.userExerciseLog.logs.splice(index, 1);
    },
    duplicateLastSet() {
      let logs = this.userExerciseLog.logs;

      if (logs.length) {
        let lastLog = logs[logs.length - 1];
        logs.push({ ...lastLog });
      }
    },
    add() {
      this.userExerciseLog.logs.push({
        weight: "",
        reps: "",
        time: "",
        band: "",
      });
    },
  },

  created() {
    if (!this.userExerciseLog.logs.length) {
      this.add();
    }
  },
};
</script>