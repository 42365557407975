<template>
  <div class="exercise_control">
    <div class="exercise_dcontrol">
      <div class="stopwatch">
        <div class="popup_close close" data-dismiss="modal"></div>
        <div class="stopwatch_icon">
          <i class="atuicons atuicons-clock"></i>
        </div>
        <div class="stopwatch_time stopwatchTime">00:00:00</div>
        <div class="stopwatch_buttons">
          <span class="button button_white" id="startStopwatch"
            ><i></i><span>Start Timer</span></span
          ><span
            class="button button_white"
            id="pauseStopwatch"
            style="display: none"
            ><i></i><span>Pause Timer</span></span
          ><span class="button button_green" id="stopStopwatch"
            ><i class="atuicons atuicons-tick"></i><span>Stop Timer</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Control",
};
</script>
