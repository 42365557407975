<template>
  <div class="workouts_exitem">
    <div class="workouts_ex_img">
      <div
        class="workouts_ex_quality"
        v-show="showQualitySettingsIcon"
        @click="showVideoResolutionButtons = true"
      >
        <i class="atuicons atuicons-video-setting"></i>
      </div>
      <div class="workouts_ex_close">
        <i class="atuicons atuicons-close"></i>
      </div>
      <div
        class="workouts_ex__img"
        :style="{
          backgroundImage: `url(${exercise.files.thumbnail})`,
        }"
      >
        <video
          :id="`swap_video_${exercise.id}`"
          :poster="exercise.files.thumbnail"
          controlsList="nodownload"
        >
          <source
            :id="`swap_source_${exercise.id}`"
            :src="videoSrc"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="playvideo">
        <a class="playbutton" href="#"></a>
        <div class="title_small">Play Video</div>
      </div>
      <div class="workouts_ex_showdesc">
        <a
          class="title_small showDescription"
          href="#"
          @click.prevent="showDetail(exercise, serialNumber + 1)"
          >Read Instructions</a
        >
      </div>
      <div
        :class="{
          workouts_ex_buttons: true,
          opened: showVideoResolutionButtons,
        }"
      >
        <button
          :class="{
            button: true,
            button_white: true,
            current: 'low' == selectedVideoResolution,
          }"
          data-quality="low"
          @click="changeVideoResolution('low')"
        >
          <i class="atuicons atuicons-tick"></i>Lower resolution</button
        ><button
          :class="{
            button: true,
            button_white: true,
            current: 'high' == selectedVideoResolution,
          }"
          data-quality="high"
          @click="changeVideoResolution('high')"
        >
          <i class="atuicons atuicons-tick"></i>High resolution
        </button>
      </div>
    </div>
    <div class="workouts_ex_description">
      <div
        class="workouts_ex_muscles"
        :style="{
          backgroundImage: `url(${exercise.image})`,
        }"
      ></div>
      <div class="workouts_ex_title">
        #{{ serialNumber + 1 }}. {{ exercise.name }}
      </div>
      <div class="workouts_ex_setreps">
        <div class="workouts_ex_sets" v-if="exercise.sets">
          <div class="workouts_ex_setreps_t">
            SETS: <strong>{{ exercise.sets }}</strong>
          </div>
        </div>
        <div class="workouts_ex_reps" v-if="exercise.reps">
          <div class="workouts_ex_setreps_t">
            REPS: <strong>{{ exercise.reps }}</strong>
          </div>
        </div>

        <div class="workouts_ex_reps" v-if="exercise.sideType">
          <div class="workouts_ex_setreps_t">
            SIDE: <strong>{{ exercise.sideType }}</strong>
          </div>
        </div>

        <div class="workouts_ex_reps" v-if="exercise.time">
          <div class="workouts_ex_setreps_t">
            TIME: <strong>{{ exercise.time}}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="exswap_selectbutton">
      <a
        class="button button_small button_grey"
        href="#"
        @click.prevent="handleSelect(exercise, false)"
        ><span>Select</span><i class="atuicons atuicons-tick"></i>
      </a>
    </div>
  </div>
</template>
<script>
import NProgress from "nprogress";
import $ from "jquery";

export default {
  name: "SwapExercise",
  props: {
    exercise: Object,
    serialNumber: Number,
    information: Object,
  },
  data() {
    return {
      showQualitySettingsIcon: true,
      showVideoResolutionButtons: false,
      selectedVideoResolution: "high",
      videoSrc: "",
    };
  },
  mounted() {
    this.selectedVideoResolution = this.profile.properties.video_resolution;
    this.videoSrc = this.exercise.files.videos[this.selectedVideoResolution];
  },
  methods: {
    changeVideoResolution(resolution) {
      this.selectedVideoResolution = resolution;
      console.log(this.exercise.files.videos);
      console.log(this.selectedVideoResolution);
      this.videoSrc = this.exercise.files.videos[this.selectedVideoResolution];
      this.showVideoResolutionButtons = false;

      var video = document.getElementById(`swap_video_${this.exercise.id}`);
      var source = document.getElementById(`swap_source_${this.exercise.id}`);
      setTimeout(() => {
        let t = video.currentTime;
        video.pause();
        source.setAttribute("src", this.videoSrc);
        source.setAttribute("type", "video/mp4");
        video.load();
        video.currentTime = t;
        var playPromise = video.play();
        NProgress.start();
        this.showQualitySettingsIcon = false;

        if (playPromise !== undefined) {
          playPromise
            .catch((error) => {
              console.log("swap exercise video error", error);
            })
            .finally(() => {
              NProgress.done();
              this.showQualitySettingsIcon = true;
            });
        }
        console.log({
          src: source.getAttribute("src"),
          type: source.getAttribute("type"),
        });
      }, 0);
    },
    handleSelect(selectedExericse) {
      const originalExercise = this.originalExercise;
      Object.assign(originalExercise, selectedExericse);

      $("body").removeClass("modalopen");
      $(".exercise_swap").fadeOut();

      let uniqueKey = `${this.information.day}-${this.information.month}-${this.information.type}-${this.info.index}`;
      let video = document.getElementById(`video_${uniqueKey}`);
      let source = document.getElementById(`source_${uniqueKey}`);
      video.pause();
      console.log("swap_video_url", selectedExericse.files.videos.low);
      source.setAttribute("src", selectedExericse.files.videos.low);
      source.setAttribute("type", "video/mp4");
      video.load();
    },
    showDetail(exercise, serialNumber) {
      const item = {
        name: exercise.name,
        description: exercise.description,
        serialNumber,
      };
      this.$store.dispatch("exerciseDescription/selectExercise", item);
    },
  },
  computed: {
    info: function () {
      return this.$store.getters.swap.info;
    },
    profile() {
      return this.$store.getters.profile;
    },
    originalExercise: function () {
      return this.$store.getters.swap.originalExercise;
    },
  },
};
</script>