<template>
  <div class="workouts_exlist">
    <Exercise
      v-for="(exercise, index) in exercises"
      :key="getUniqueKey(index)"
      :exercise="exercise"
      :serialNumber="index + 1"
      :info="info"
      :workoutId="workout.id"
      :uniqueKey="getUniqueKey(index)"
    >
    </Exercise>
  </div>
</template>

<script>
import Exercise from "./ExerciseList/Exercise";

export default {
  name: "ExerciseList",
  props: { workout: Object, info: Object },
  components: { Exercise },
  data() {
    return {
      completedExerciseList: [],
    };
  },
  computed: {
    exercises: function () {
      return this.workout.exercises;
    },
  },
  methods: {
    getUniqueKey(position) {
      return `${this.info.day}-${this.info.month}-${this.info.type}-${position}`;
    },
  },
  created() {
  },
};
</script>