<template>
  <div class="exercise_congratulations" v-if="!this.isLoading">
    <div v-if="!isCompleted" class="exercise_markdone" id="markdone">
      <a class="button button_black" href="#" @click.prevent="add"
        ><i class="atuicons atuicons-exfinish"></i>I Finished My Workout</a
      >
      <div class="exercise_markdone_tip">
        please tap I Finished so we can record your progress
      </div>
    </div>
    <div v-if="isCompleted" class="congratulations" style="display: block">
      <div class="congratulations_icon">
        <i class="atuicons atuicons-human"></i>
      </div>
      <div class="congratulations_t">
        Congratulations!<br />Your workout is done.
      </div>
    </div>
    <div
      v-if="isCompleted"
      class="exercise_markdone_tip"
      id="markdoneCancel"
      style="display: block"
      @click="remove"
    >
      If you clicked done by mistake, you can go back.<br />Please click here
    </div>
  </div>
</template>

<script>
import NProgress from "nprogress";
import { getWorkoutLog } from "@/api/workoutlog";

export default {
  name: "Congratulation",
  props: { info: Object },
  data() {
    return {
      isLoading: false,
      isCompleted: false,
    };
  },
  created() {
    this.sync();
  },
  methods: {
    sync() {
      this.isLoading = true;
      getWorkoutLog(this.info)
        .then(({ data }) => {
          this.isLoading = true;
          this.isCompleted = data.isCompleted;
        })
        .catch(() => {
          console.log("error while adding workout log");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    add() {
      NProgress.start();
      this.isLoading = true;
      this.$store
        .dispatch("workoutlog/add", this.info)
        .then(() => {
          this.isLoading = true;
          this.isCompleted = true;
        })
        .catch(() => {
          this.isLoading = false;
          console.log("error while adding workout log");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },

    remove() {
      NProgress.start();
      this.isLoading = true;
      this.$store
        .dispatch("workoutlog/remove", this.info)
        .then(() => {
          this.isLoading = true;
          this.isCompleted = false;
        })
        .catch(() => {
          this.isLoading = false;
          console.log("error while removing workout log");
        })
        .finally(() => {
          NProgress.done();
          this.isLoading = false;
        });
    },
  },
};
</script>
