<template>
  <div class="exercise_swap" id="swapGym">
    <div class="exswap_dcenter">
      <div class="exswap">
        <div class="exswap_control">
          <div class="exswap_control_icon">
            <i class="atuicons atuicons-exhome"></i>
          </div>
          <div class="workouts_ex_close exswap_cancel">
            <i class="atuicons atuicons-close"></i>
          </div>
        </div>
        <div class="exswap_head">
          <div class="exswap_head_t">
            <i class="atuicons atuicons-exhome"></i>Swap For a
            {{ info.type | capitalize }} Alternative
          </div>
          <div class="exswap_close">
            <i class="atuicons atuicons-close"></i>
          </div>
        </div>
        <div class="exswap_content">
          <div class="title_small">
            Exercises For {{ info.type | capitalize }}
          </div>
          <div class="exswap_list">
            <SwapExercise
              v-for="(exercise, index) in exercises"
              :key="exercise.id"
              :exercise="exercise"
              :serialNumber="index"
              :information="information"
            >
            </SwapExercise>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SwapExercise from "./Swap/SwapExercise";

export default {
  name: "Swap",
  props: { information: Object },
  components: { SwapExercise },
  computed: {
    exercises: function () {
      return this.$store.getters.swap.exercises;
    },
    info: function () {
      return this.$store.getters.swap.info;
    },
  },
};
</script>