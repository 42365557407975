import request from '@/utils/request'

export function getTodayWorkout(params) {
    return request({
        url: `/api/workout/view`,
        method: 'get',
        params
    })
}

