<template>
  <div class="exercise_swap description_popup">
    <div class="exswap_dcenter">
      <div class="exswap">
        <div class="exswap_control">
          <a class="exswap_cancel" href="#"
            ><i class="atuicons atuicons-close"></i
          ></a>
        </div>
        <div class="exswap_head">
          <div class="exswap_head_t">Description</div>
          <div class="exswap_close">
            <i class="atuicons atuicons-close"></i>
          </div>
        </div>
        <div class="exercise_description">
          <div class="exercise_desc">
            <div class="exercise_flex">
              <div class="exercise_text">
                <div class="exercise_name">
                  #{{ item.serialNumber }} - {{ item.name }}
                </div>
                <p>
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionPopup",
  computed: {
    item() {
      return this.$store.getters.exerciseDescription.exercise;
    },
  },
};
</script>
